<template>
  <section class="dtc-main-section">
    <b-overlay :show="show" rounded="sm">
      <vue-tabs>
        <v-tab
          v-for="(item, i) in allUserRight"
          :title="item.text"
          :key="item + i"
        >
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '學校維護設定'"
              :src="`${domainObject.recruit}/#/apply20`"
              frameborder="0"
            ></iframe>
          </span>

          <iframe
            v-if="item.text == '學校科系設定'"
            :src="`${domainObject.recruit}/#/applyschoool`"
            frameborder="0"
          ></iframe>

          <iframe
            v-if="item.text == '偏鄉籍定義設定'"
            :src="`${domainObject.recruit}/#/apply30`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '成績計算參考設定'"
            :src="`${domainObject.recruit}/#/apply19`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '籍屬身分確認開放時間'"
            :src="`${domainObject.recruit}/#/apply22`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '面試日期設定'"
            :src="`${domainObject.recruit}/#/apply23`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '成績公布日期設定'"
            :src="`${domainObject.recruit}/#/apply23-2`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '填寫志願日期設定'"
            :src="`${domainObject.recruit}/#/apply23-1`"
            frameborder="0"
          ></iframe>
        </v-tab>
      </vue-tabs>
    </b-overlay>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import queryString from "query-string";
import { domainObject } from "@/constant.js";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

const titles = [
  "學校維護設定",
  "學校科系設定",
  "成績計算參考設定",
  "籍屬身分確認開放時間",
  "面試日期設定",
  "成績公布日期設定",
  "填寫志願日期設定",
];
export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      titles,
      showTab1: false,
      show: true,
      allUserRight: [
        { text: "學校維護設定", isActive: false, functionId: "10020" },
        { text: "學校科系設定", isActive: false, functionId: "10021" },
        { text: "偏鄉籍定義設定", isActive: false, functionId: "10022" },
        { text: "成績計算參考設定", isActive: false, functionId: "10023" },
        { text: "籍屬身分確認開放時間", isActive: false, functionId: "10024" },
        { text: "面試日期設定", isActive: false, functionId: "10025" },
        { text: "成績公布日期設定", isActive: false, functionId: "10026" },
        { text: "填寫志願日期設定", isActive: false, functionId: "10027" },
      ],
      userRights: [],
      domainObject,
    };
  },

  components: {
    VueTabs,
    VTab,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.allUserRight = this.allUserRight.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.allUserRight = this.allUserRight.filter((s) => s.isActive);
      // let allData = (this.allData = JSON.parse(localStorage.dtxBuget));
      // allData = allData.find((s) => s.MenuName == "招生服務")
      //   ? allData.find((s) => s.MenuName == "招生服務")
      //   : {};
      // if (Boolean(allData.SubMenu)) {
      //   this.allUserRight = !allData.SubMenu.length
      //     ? []
      //     : allData.SubMenu.find((s) => s.MenuName == "招生基本資料設定");
      //   if (this.allUserRight.SubMenu.length) {
      //     this.allUserRight.SubMenu.forEach((s) => {
      //       this.userRights.push(s.MenuName);
      //     });
      //   }
      // }
    },
  },
  async mounted() {
    setTimeout(() => {
      this.showTab1 = true;
      this.show = false;
    }, 0);
    this.setUserRight();
  },
  beforeMount() {},
};
</script>
<style lang="scss">
.dtc-main-section {
  height: 100vh;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
